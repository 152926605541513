import React from 'react';
import PrivacyPolicy from '../parts/PrivacyPolicy';
import PageWrapper from '../containers/PageWrapper';
import WebsiteWrapper from '../parts/WebsiteWrapper';

const PrivacyPolicyPage = ({ pageContext, location }) => {
  const { lang, translations } = pageContext;
  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <PrivacyPolicy />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default PrivacyPolicyPage;
